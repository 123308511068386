.container,
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.link {
  display: block;
  width: 100%;
  text-decoration: underline;
  font-style: italic;
  color: blue;
  font-weight: bold;
  font-size: 1.2rem;
}

@media screen and (min-width: 760px) {
  .wrapper {
    width: 70%;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 1100px) {
  .wrapper {
    width: 80%;
  }
}
