.container,
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.image {
  border-radius: 50%;
  width: 50vw;
  height: 50vw;
  object-fit: cover;
}

.intro {
  margin: 1rem 0;
  font-weight: bold;
  font-size: 1.3rem;
  font-family: var(--font-playfair);
  padding: 0 1rem;
}

.details {
  margin: 1rem 0;
  padding: 0 1rem;
}

.quote {
  font-style: italic;
  padding: 0 1rem;
}

@media screen and (min-width: 760px) {
  .wrapper {
    width: 70%;
  }

  .image {
    width: 30vw;
    height: 30vw;
  }
}
