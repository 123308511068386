.image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  margin: 0 auto;
}

.image:hover {
  cursor: pointer;
  filter: brightness(90%);
}
