.item {
  text-transform: uppercase;
  border-bottom: 1px solid transparent;
  color: white;
  line-height: 1.4rem;
}

.item:hover {
  border-bottom: 1px solid white;
  cursor: pointer;
}
