.container {
  border-bottom: 2px solid var(--bg-color-black);
  border-top: 2px solid var(--bg-color-black);
  text-align: center;
  font-weight: bold;
  font-size: 1.75rem;
  font-family: var(--font-playfair);
  width: 100%;
  margin: 2rem 0;
  padding: 1rem;
  box-shadow: 0px 5px 5px var(--bg-color-black);
}
