.container {
  display: flex;
  color: white;
  width: 100%;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.image {
  width: 30px;
}

.container:hover {
  transform: scale(1.1);
}
