.container {
  width: 100%;
  height: 100vh;
}

.wrapper {
  display: flex;
  height: 90%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: center;
}

.contact_img {
  width: 150px;
  transition: 1s;
  margin: 10px;
}

.contact_img:hover {
  transform: rotateZ(359deg);
  transition: 1s;
}

.contact_link {
  text-decoration: none;
  font-size: 1.4rem;
  color: var(--bg-color-blue);
  transition: 1s;
}

.contact_link:hover {
  transform: scale(1.2);
}

.social {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: center;
}
