@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Playfair+Display:wght@400;700&display=swap');

:root {
  --bg-color-black: #0f0f13;
  --bg-color-blue: #242e50;
  --bg-color-blue-low-opacity: #hsla(0, 0%, 100%, 0.2);
  --color-white-low-opacity: rgba(255, 255, 255, 0.05);
  --color-shadow: rgba(255, 255, 255, 0.5);

  --font-playfair: 'Playfair Display', sans-serif;
}

* {
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  color: var(--bg-color-blue);
}

a {
  text-decoration: none;
  color: var(--bg-color-blue);
}

h1 {
  font-size: 1.6rem;
  font-family: var(--font-playfair);
}

.size1 {
  font-size: 1rem;
}

.size2 {
  font-size: 1.4rem;
}

.size3 {
  font-size: 1.7rem;
}
