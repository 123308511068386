.container {
  position: absolute;
  top: 0;
  width: 100%;
}

@media screen and (min-width: 760px) {
  .children {
    width: calc(100% - 310px);
    position: static;
    float: right;
  }
}

@media screen and (min-width: 1300px) {
}
