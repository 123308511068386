.title {
  font-family: 'Payfair Display';
  font-size: 2.5rem;
  text-decoration: underline;
  margin-bottom: 1rem;
}

.level_container {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
}
